var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useState } from 'react';
import ReactPlayer from 'react-player/lazy';
import { style } from '../util/style';
import './Video.scss';
var styles = style('video');
export var Video = function (_a) {
    var video_link = _a.video_link, ratio = _a.ratio, onReady = _a.onReady, _b = _a.autoPlay, autoPlay = _b === void 0 ? true : _b, _c = _a.muted, muted = _c === void 0 ? true : _c;
    var _d = useState(false), isPlaying = _d[0], setPlaying = _d[1];
    if (!video_link)
        return null;
    return (_jsx("div", __assign({ className: styles.mod({ ratio: ratio }) }, { children: _jsx("div", __assign({ className: styles('wrapper'), onClick: function () { return setPlaying(!isPlaying); } }, { children: _jsx(ReactPlayer, { url: video_link.url, playing: true, height: '100%', width: '100%', controls: true, style: {
                    position: 'absolute',
                    left: 0,
                    right: 0,
                    zIndex: 1,
                }, onReady: function () { return onReady(); }, autoPlay: autoPlay, muted: muted }) })) })));
};
export var VideoPlayButton = function () {
    return (_jsx("svg", __assign({ id: "Capa_1", enableBackground: "new 0 0 437.499 437.499", height: "512", viewBox: "0 0 437.499 437.499", width: "512", xmlns: "http://www.w3.org/2000/svg" }, { children: _jsx("path", { fill: "#ffffff", d: "m46.875 437.498c-2.67 0-5.341-.687-7.751-2.06-4.868-2.777-7.874-7.95-7.874-13.566v-406.27c0-5.616 3.006-10.789 7.874-13.566 4.913-2.762 10.88-2.701 15.701.107l343.749 203.136c4.761 2.823 7.675 7.935 7.675 13.459s-2.914 10.636-7.675 13.459l-343.749 203.135c-2.457 1.435-5.204 2.167-7.95 2.166zm15.625-394.521v351.521l297.409-175.76z" }) })));
};
