var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
var cache = new Map();
// Fool typescript into marking these properties as non enumerable so
// we can jsx spread the result
var ClassApi = /** @class */ (function () {
    function ClassApi() {
    }
    ClassApi.prototype.with = function () {
        var _extra = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            _extra[_i] = arguments[_i];
        }
        throw new Error('assert');
    };
    ClassApi.prototype.mergeProps = function (_attrs) {
        throw new Error('assert');
    };
    ClassApi.prototype.sub = function (_sub) {
        throw new Error('assert');
    };
    ClassApi.prototype.is = function (_state) {
        throw new Error('assert');
    };
    ClassApi.prototype.mod = function (_modifier) {
        throw new Error('assert');
    };
    ClassApi.prototype.toSelector = function () {
        throw new Error('assert');
    };
    ClassApi.prototype.toString = function () {
        throw new Error('assert');
    };
    ClassApi.prototype.toElement = function (_element) {
        throw new Error('assert');
    };
    return ClassApi;
}());
var states = function (selector, name, state) {
    var _a;
    return (_a = className(String(selector))).with.apply(_a, Object.entries(state)
        .map(function (_a) {
        var cl = _a[0], active = _a[1];
        return active && cl;
    })
        .filter(function (v) { return v; })
        .map(function (cl) { return "".concat(name, "-").concat(cl); }));
};
var createStyler = function (input, value) {
    input.className = String(value);
    Object.defineProperties(input, {
        with: {
            enumerable: false,
            value: function () {
                var extra = [];
                for (var _i = 0; _i < arguments.length; _i++) {
                    extra[_i] = arguments[_i];
                }
                return className([]
                    .concat(extra)
                    .concat(value)
                    .filter(function (v) { return v; })
                    .join(' '));
            }
        },
        mergeProps: {
            enumerable: false,
            value: function (props) {
                var a = props.class;
                var b = props.className;
                // tslint:disable-next-line:triple-equals
                return className(value).with(a, a != b && b);
            }
        },
        sub: {
            enumerable: false,
            value: function (sub) {
                return style("".concat(value, "_").concat(sub));
            }
        },
        is: {
            enumerable: false,
            value: function (state) {
                return states(value, 'is', state);
            }
        },
        mod: {
            enumerable: false,
            value: function (modifier) {
                if (!modifier)
                    return this;
                if (Array.isArray(modifier))
                    return className("".concat(modifier.map(function (c) { return "mod-".concat(c); }).join(' '), " ").concat(value));
                if (typeof modifier === 'object')
                    return states(value, 'mod', modifier);
                return className("mod-".concat(modifier, " ").concat(value));
            }
        },
        toSelector: {
            enumerable: false,
            value: function () {
                var joined = String(value)
                    .split(' ')
                    .join('.');
                if (joined.length > 0)
                    return ".".concat(joined);
                return '';
            }
        },
        toElement: {
            enumerable: false,
            value: function (element) {
                var _this = this;
                return function (_a) {
                    var _b = _a.as, Tag = _b === void 0 ? element : _b, props = __rest(_a, ["as"]);
                    return (_jsx(Tag, __assign({}, props, _this.mergeProps(props))));
                };
            }
        },
        toString: {
            value: function () {
                return String(value);
            }
        }
    });
};
var className = function (selector) {
    if (cache.has(selector))
        return cache.get(selector);
    var inst = {};
    createStyler(inst, selector);
    cache.set(selector, inst);
    return inst;
};
export var style = function (selector, subs) {
    if (subs === void 0) { subs = []; }
    var key = "".concat(selector, "//(").concat(subs, ")");
    if (cache.has(key))
        return cache.get(key);
    var inst = function (sub) {
        return !sub ? className(selector) : className("".concat(selector, "-").concat(sub));
    };
    createStyler(inst, selector);
    subs.forEach(function (sub) {
        return Object.defineProperty(inst, sub, {
            enumerable: false,
            value: style("".concat(selector, "_").concat(sub))
        });
    });
    cache.set(key, inst);
    return inst;
};
