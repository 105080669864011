var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
export var Logo = function () {
    return (_jsxs("svg", __assign({ xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 32 32" }, { children: [_jsx("rect", { x: "2.58", y: "2.58", width: "26.83", height: "26.83", rx: "0.67", style: {
                    fill: 'transparent',
                    stroke: '#3d3d3d',
                    strokeMiterlimit: 10,
                    strokeWidth: '0.400000005960465px'
                } }), _jsx("path", { d: "M14.43,13.7h-.49V11.85a3.74,3.74,0,0,0-1.56-1.33,5.46,5.46,0,0,0-2.3-.45,4.55,4.55,0,0,0-2.85.8,2.37,2.37,0,0,0-.18,3.79A8.12,8.12,0,0,0,10.18,16a9.82,9.82,0,0,1,3.32,1.42,2.81,2.81,0,0,1,1.12,2.38,2.87,2.87,0,0,1-1.28,2.47,5.42,5.42,0,0,1-3.2.9,6.68,6.68,0,0,1-2.54-.49,4.77,4.77,0,0,1-2-1.55V18.94h.49v2a3.93,3.93,0,0,0,1.8,1.34,6.31,6.31,0,0,0,2.28.42,4.83,4.83,0,0,0,2.86-.8,2.54,2.54,0,0,0,.21-4.05,8.9,8.9,0,0,0-3.16-1.36,10.46,10.46,0,0,1-3.31-1.38,2.64,2.64,0,0,1-1-2.27,2.74,2.74,0,0,1,1.23-2.35,5.26,5.26,0,0,1,3.15-.88,6.14,6.14,0,0,1,2.59.51,4,4,0,0,1,1.76,1.54Z", style: { fill: '#3d3d3d' } }), _jsx("path", { d: "M21.12,9.77a5.23,5.23,0,0,1,3.18.84,3,3,0,0,1,1.14,2.54,3.2,3.2,0,0,1-.61,2,2.64,2.64,0,0,1-1.72,1,3.33,3.33,0,0,1,2.08,1.08A3,3,0,0,1,26,19.33,3.33,3.33,0,0,1,24.91,22,4.42,4.42,0,0,1,22,23H16.17v-.49h1.48V10.26H16.17V9.77h4.95Zm-3,6.19H21.3a4.42,4.42,0,0,0,2.72-.7,2.55,2.55,0,0,0,.92-2.15A2.5,2.5,0,0,0,24,11a4.71,4.71,0,0,0-2.85-.72h-3Zm0,.49v6H22a3.89,3.89,0,0,0,2.63-.82,2.9,2.9,0,0,0,1-2.31,2.68,2.68,0,0,0-.92-2.06A3.68,3.68,0,0,0,22,16.45Z", style: { fill: '#3d3d3d' } })] })));
};
