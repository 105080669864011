var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import './Button.scss';
import { ButtonBase } from '@material-ui/core';
import { Link } from '../util/link';
import { style } from '../util/style';
var styles = style('button');
export var Button = function (_a) {
    var children = _a.children, type = _a.type, onClick = _a.onClick, rest = __rest(_a, ["children", "type", "onClick"]);
    switch (type) {
        case 'submit':
            return _jsx(ButtonSubmit, __assign({}, rest));
        case 'link':
            return _jsx(ButtonLink, __assign({}, rest));
        default:
            return (_jsx("div", __assign({ className: styles(), onClick: onClick }, rest, { children: _jsx(ButtonBase, { children: _jsx("div", __assign({ className: styles('inner') }, { children: children })) }) })));
    }
};
export var ButtonSubmit = function (_a) {
    var children = _a.children, rest = __rest(_a, ["children"]);
    return (_jsx("div", __assign({ className: styles() }, rest, { children: _jsx(ButtonBase, { children: _jsx("input", __assign({ className: styles('submit'), type: "submit" }, { children: children })) }) })));
};
export var ButtonLink = function (_a) {
    var children = _a.children, link = __rest(_a, ["children"]);
    return (_jsx("div", __assign({ className: styles() }, { children: _jsx(ButtonBase, { children: _jsx(Link, __assign({ className: styles('inner') }, link, { type: "submit" }, { children: children })) }) })));
};
