var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { Theme } from '../Theme';
import { urlFor } from '../util/imageBuilder';
import { style } from '../util/style';
import './Gear.scss';
import { WorkUseLink } from './Work';
var styles = style('gear', ['item']);
var Gear = function (_a) {
    var items = _a.items, pageData = _a.pageData;
    var _b = useState(false), isLoaded = _b[0], setLoaded = _b[1];
    return (_jsx("div", __assign({ className: styles() }, { children: _jsxs(Theme.Container, { children: [_jsx(Theme.H1, { children: pageData === null || pageData === void 0 ? void 0 : pageData.title }), _jsxs("div", __assign({ className: styles('columns') }, { children: [_jsx("div", __assign({ className: styles('columns-column') }, { children: items
                                .sort(function (a, b) { return a.order - b.order; })
                                .map(function (gear, i) { return (_jsx(GearItem, __assign({}, gear), i)); }) })), _jsx("div", __assign({ className: styles('columns-column')
                                .mod('image')
                                .is({ loaded: isLoaded }) }, { children: pageData && (_jsx("img", { alt: "Gear", className: "picture image", onLoad: function () { return setLoaded(true); }, width: 492, height: 328, src: urlFor(pageData.image).size(492, 328).url() })) }))] }))] }) })));
};
export var GearItem = function (_a) {
    var title = _a.title, subtitle = _a.subtitle, title_url = _a.title_url, subtitle_url = _a.subtitle_url;
    return (_jsxs("div", __assign({ className: styles.item() }, { children: [_jsx(WorkUseLink, __assign({ externalLink: title_url, className: styles.item('title') }, { children: _jsx(Theme.H2, { children: title }) })), _jsx(WorkUseLink, __assign({ externalLink: subtitle_url, className: styles.item('info') }, { children: _jsx(Theme.P, { children: subtitle }) }))] })));
};
export { Gear as default };
