var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { withRouterHOC } from '@sanity/state-router/components';
import { lazy, Suspense } from 'react';
import './App.scss';
import { Error404 } from './pages';
import { PageLoader } from './partials/PageLoader';
import './Reset.scss';
import { SanityFetcher } from './util/Fetcher';
import { Layout } from './layout/Layout';
import { Files } from './pages/Files';
import { history } from '..';
window.process = {};
var HomePage = lazy(function () { return import('./pages/Home'); });
var WorkPage = lazy(function () { return import('./pages/Work'); });
var GearPage = lazy(function () { return import('./pages/Gear'); });
var AboutPage = lazy(function () { return import('./pages/About'); });
export var isLive = window.location.origin === 'https://sanderblok.com';
var redirects = {
    '/en/about': '/about',
    '/en/home': '/home',
    '/en/work': '/work',
    '/en/gear': '/gear',
};
export var App = withRouterHOC(function App(_a) {
    var _b;
    var router = _a.router;
    var generalQuery = '_type == "general" => {..., _type == "navigationItem" => {"slug": @.item->slug}}';
    var pageQuery = "_type == \"page\" => {image, title, text, slug}";
    redirectApp();
    switch ((_b = router === null || router === void 0 ? void 0 : router.state) === null || _b === void 0 ? void 0 : _b.page) {
        case 'home':
            return (_jsx(PageFetcher, __assign({ query: "*[_type in [\"home\", \"general\"]]{\n            ".concat(generalQuery, ",\n          _type == \"home\" => {\n            intro, title, video_url_1, video_image_1, video_url_2, video_image_2, slug}\n          }") }, { children: function (data) {
                    return (_jsx(Suspense, __assign({ fallback: "" }, { children: _jsx(HomePage, __assign({ general: data.filter(function (item) { var _a; return ((_a = item === null || item === void 0 ? void 0 : item.slug) === null || _a === void 0 ? void 0 : _a.current) === 'general'; })[0] }, data.filter(function (item) { var _a; return ((_a = item === null || item === void 0 ? void 0 : item.slug) === null || _a === void 0 ? void 0 : _a.current) === 'home'; })[0])) })));
                } })));
        case 'work':
            return (_jsx(PageFetcher, __assign({ query: "*[_type in [\"work\", \"general\", \"page\"]]{\n            ".concat(generalQuery, ",\n            ").concat(pageQuery, ",\n            _type == \"work\" => {_id, _type, thumbnail, title, year, info, \"categories\": categories[]->{title, slug, order}, slug, externalLink, order}\n            }|order(order asc)") }, { children: function (data) {
                    return (_jsx(Suspense, __assign({ fallback: "" }, { children: _jsx(WorkPage, { pageData: data.filter(function (item) { var _a; return ((_a = item === null || item === void 0 ? void 0 : item.slug) === null || _a === void 0 ? void 0 : _a.current) === 'work'; })[0], items: data.filter(function (item) { return (item === null || item === void 0 ? void 0 : item._type) === 'work'; }) }) })));
                } })));
        case 'gear':
            return (_jsx(PageFetcher, __assign({ query: "*[_type in [\"gear\", \"general\", \"page\"]]{\n              ".concat(generalQuery, ",\n              ").concat(pageQuery, ",\n              _type == \"gear\" => {_id, _type, title_url, title, subtitle, subtitle_url, order}\n            }") }, { children: function (data) {
                    return (_jsx(Suspense, __assign({ fallback: "" }, { children: _jsx(GearPage, { pageData: data.filter(function (item) { var _a; return ((_a = item === null || item === void 0 ? void 0 : item.slug) === null || _a === void 0 ? void 0 : _a.current) === 'gear'; })[0], items: data.filter(function (item) { return (item === null || item === void 0 ? void 0 : item._type) === 'gear'; }) }) })));
                } })));
        case 'about':
            return (_jsx(PageFetcher, __assign({ query: "*[_type in [\"general\", \"page\"]]{\n              ".concat(generalQuery, ",\n              ").concat(pageQuery, "\n            }") }, { children: function (data) {
                    return (_jsx(Suspense, __assign({ fallback: "" }, { children: _jsx(AboutPage, { pageData: data.filter(function (item) { var _a; return ((_a = item === null || item === void 0 ? void 0 : item.slug) === null || _a === void 0 ? void 0 : _a.current) === 'about'; })[0] }) })));
                } })));
        case 'files':
            return (_jsx(PageFetcher, __assign({ query: "*[_type in [\"files\", \"general\", \"page\"]]{\n                ".concat(generalQuery, ",\n                ").concat(pageQuery, ",\n                _type == \"files\" => {_id, _type, title, info, file, \"fileUrl\": file.asset->url}\n              }") }, { children: function (data) {
                    return (_jsx(Files, { pageData: data.filter(function (item) { var _a; return ((_a = item === null || item === void 0 ? void 0 : item.slug) === null || _a === void 0 ? void 0 : _a.current) === 'files'; })[0], items: data.filter(function (item) { return (item === null || item === void 0 ? void 0 : item._type) === 'files'; }) }));
                } })));
        default:
            return (_jsx(PageFetcher, __assign({ query: "*[_type in [\"general\"]]{\n            ".concat(generalQuery, "\n          }") }, { children: function () { return _jsx(Error404, {}); } })));
    }
});
var PageFetcher = function (_a) {
    var query = _a.query, children = _a.children;
    return (_jsx(SanityFetcher, __assign({ query: query }, { children: function (_a) {
            var data = _a.data, action = _a.action;
            var canRender = (action === null || action === void 0 ? void 0 : action.type) !== null &&
                ((action === null || action === void 0 ? void 0 : action.type) === 'success' || (action === null || action === void 0 ? void 0 : action.type) === 'success|cache');
            return (_jsxs(Layout, __assign({ loadState: action, general: canRender &&
                    data &&
                    data.filter(function (item) { var _a; return ((_a = item === null || item === void 0 ? void 0 : item.slug) === null || _a === void 0 ? void 0 : _a.current) === 'general'; })[0] }, { children: [_jsx(PageLoader, { isLoading: (action === null || action === void 0 ? void 0 : action.type) === 'loading' }), canRender && data && children(data)] })));
        } })));
};
var redirectApp = function () {
    Object.entries(redirects).find(function (_a) {
        var urlToRedirect = _a[0], urlToRedirectTo = _a[1];
        if (window.location.pathname === urlToRedirect) {
            return history.push(urlToRedirectTo);
        }
    });
};
