var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { createContext } from '@codeurs/front';
var ThemeContext = createContext({});
var widget = function (view) {
    var name = Object.keys(view)[0];
    var defaultView = Object.values(view)[0];
    return function (attrs) { return (_jsx(ThemeContext.Consumer, { children: function (theme) {
            var children = attrs.children, rest = __rest(attrs, ["children"]);
            var View = theme[name] || defaultView;
            return _jsxs(View, __assign({}, rest, { children: [children, " "] }));
        } })); };
};
export var createTheme = function (views) {
    return Object.assign(function (_a) {
        var children = _a.children, extend = _a.extend;
        return (_jsx(ThemeContext.Consumer, { children: function (theme) { return (_jsx(ThemeContext.Provider, __assign({ value: __assign(__assign({}, theme), extend) }, { children: children }))); } }));
    }, Object.entries(views).reduce(function (acc, _a) {
        var _b, _c;
        var name = _a[0], value = _a[1];
        return (__assign(__assign({}, acc), (_b = {}, _b[name] = widget((_c = {}, _c[name] = value, _c)), _b)));
    }, {}));
};
