import React from 'react'
import ReactDOM from 'react-dom'
import { App } from './components/App'
import { route } from '@sanity/state-router'
import { RouterProvider } from '@sanity/state-router/components'
import { Router } from 'react-router'

import './components/Theme.scss'

if (module.hot) {
  module.hot.accept()
}

const router = route('/', [route('/:page')])

const createHistory = require('history').createBrowserHistory

export const history = createHistory()


function handleNavigate(nextUrl, { replace } = {}) {
  if (replace) {
    history.replace(nextUrl)
  } else {
    history.push(nextUrl)
  }
}

function renderApp(location) {
  
  if(location?.pathname === '/') {
    history.replace('/home')
  }

  ReactDOM.render((
    <Router navigator={history} location={window.location}>
      <RouterProvider
        router={router}
        onNavigate={handleNavigate}
        state={router.decode(location.pathname)}>
        <App />
      </RouterProvider>
    </Router>
  ), document.getElementById('app'))
}

history.listen(() => renderApp(document.location))

renderApp(document.location)

