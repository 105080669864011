var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import './Error404.scss';
import { style } from '../util/style';
import { Theme } from '../Theme';
var styles = style('error');
export var Error404 = function () {
    return (_jsx("div", __assign({ className: styles() }, { children: _jsx(Theme.Container, { children: _jsxs("div", __assign({ className: styles('content') }, { children: [_jsx(Theme.H1, __assign({ className: styles('content-title') }, { children: "Page not available" })), _jsx("div", __assign({ className: styles('content-button') }, { children: _jsx("a", __assign({ href: "/" }, { children: _jsx(Theme.Button, __assign({ to: '/' }, { children: "Go to homepage" })) })) }))] })) }) })));
};
