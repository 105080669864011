var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useLocation } from 'react-router';
import { style } from '../util/style';
import { Tracking } from '../util/tracking';
import { Footer } from './Footer';
import { Header } from './Header';
import './Layout.scss';
var styles = style('layout');
export var Layout = function (props) {
    var location = useLocation();
    var general = props.general, children = props.children, loadState = props.loadState;
    var canRender = (loadState === null || loadState === void 0 ? void 0 : loadState.type) !== null &&
        ((loadState === null || loadState === void 0 ? void 0 : loadState.type) === 'success' || (loadState === null || loadState === void 0 ? void 0 : loadState.type) === 'success|cache');
    var navigationArray = [
        { slug: '/work', title: 'Work' },
        { slug: '/gear', title: 'Gear' },
        { slug: '/about', title: 'About' },
    ];
    return (_jsxs("div", __assign({ className: styles() }, { children: [_jsx(Header, { navigation: navigationArray, currentPath: location.pathname }), canRender && _jsx("div", __assign({ className: styles('content') }, { children: children })), canRender && (_jsx(Footer, { phone: general === null || general === void 0 ? void 0 : general.phone, email: general === null || general === void 0 ? void 0 : general.email, copyright: general === null || general === void 0 ? void 0 : general.copyright })), canRender && _jsx(Tracking, {})] })));
};
