var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Theme } from '../Theme';
import { style } from '../util/style';
import './Files.scss';
import { WorkUseLink } from './Work';
var styles = style('files', ['item']);
export var Files = function (_a) {
    var items = _a.items, pageData = _a.pageData;
    return (_jsx("div", __assign({ className: styles() }, { children: _jsxs(Theme.Container, { children: [pageData && _jsx(Theme.H1, { children: pageData === null || pageData === void 0 ? void 0 : pageData.title }), _jsx("div", __assign({ className: styles('columns') }, { children: _jsx("div", __assign({ className: styles('columns-column') }, { children: items.map(function (file) {
                            return file && (_jsx(FileItem, { title: file.title, fileUrl: file.fileUrl, info: file.info }));
                        }) })) }))] }) })));
};
export var FileItem = function (_a) {
    var title = _a.title, fileUrl = _a.fileUrl, info = _a.info;
    if (!fileUrl || !title) {
        return null;
    }
    return (_jsxs("div", __assign({ className: styles.item() }, { children: [_jsx(WorkUseLink, __assign({ externalLink: fileUrl, className: styles.item('title') }, { children: _jsx(Theme.H2, { children: title }) })), info && _jsx(Theme.P, { children: info })] })));
};
