var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import './About.scss';
import { useState } from 'react';
import { style } from '../util/style';
import { Theme } from '../Theme';
import { urlFor } from '../util/imageBuilder';
var styles = style('about');
var About = function (_a) {
    var pageData = _a.pageData;
    var _b = useState(false), isLoaded = _b[0], setLoaded = _b[1];
    return (_jsx("div", __assign({ className: styles() }, { children: _jsxs(Theme.Container, { children: [_jsx(Theme.H1, { children: (pageData === null || pageData === void 0 ? void 0 : pageData.title) || 'About' }), _jsxs("div", __assign({ className: styles('container') }, { children: [_jsx("div", __assign({ className: styles('container-image').is({ loaded: isLoaded }) }, { children: (pageData === null || pageData === void 0 ? void 0 : pageData.image) && (_jsx("img", { alt: "About Sander Blok", className: "image picture", onLoad: function () { return setLoaded(true); }, width: 215, height: 331, src: urlFor(pageData === null || pageData === void 0 ? void 0 : pageData.image).size(215, 331).url() })) })), _jsx("div", __assign({ className: styles('container-description').is({ loaded: isLoaded }) }, { children: (pageData === null || pageData === void 0 ? void 0 : pageData.text) && _jsx(Theme.Wysiwyg, { children: pageData.text }) }))] }))] }) })));
};
export { About as default };
