var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Carousel, useCarousel } from '@codeurs/carousel';
import { Picture } from '@codeurs/front';
import { useMediaQuery } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { Theme } from '../Theme';
import { BackSvg } from '../ui/svg/back';
import { NextSvg } from '../ui/svg/next';
import { Video } from '../ui/Video';
import { urlFor } from '../util/imageBuilder';
import { style } from '../util/style';
import './Home.scss';
var styles = style('home', ['slider']);
var Home = function (_a) {
    var props = __rest(_a, []);
    var video_url_1 = props.video_url_1, video_url_2 = props.video_url_2, video_image_1 = props.video_image_1, video_image_2 = props.video_image_2;
    var videos = [
        { video: video_url_1, thumbnail: video_image_1 },
        { video: video_url_2, thumbnail: video_image_2 },
    ];
    var isPortraitOrMobile = useMediaQuery('(max-width:768px)');
    if (videos === null || (videos === null || videos === void 0 ? void 0 : videos.length) === 0) {
        return _jsx(_Fragment, {});
    }
    return (_jsx("div", __assign({ className: styles() }, { children: _jsxs(Theme.Container, { children: [_jsx("div", __assign({ className: styles('title') }, { children: _jsx(Theme.H1, { children: "Sander Blok - Steadicam and Camera Operator" }) })), _jsx("div", __assign({ className: styles('videoview') }, { children: isPortraitOrMobile ? (_jsx(VideoSlider, { videos: videos })) : (_jsx(VideoView, { videos: videos })) }))] }) })));
};
export var VideoSlider = function (_a) {
    var videos = _a.videos;
    var carousel = useCarousel();
    var _b = useState(false), isLoaded = _b[0], setLoaded = _b[1];
    if (!videos || videos.length === 0)
        return null;
    return (_jsxs("div", __assign({ className: styles.slider() }, { children: [_jsx(Carousel, __assign({}, carousel, { children: videos.map(function (item, i) {
                    var _a;
                    var thumbnail = item.thumbnail, video = item.video;
                    return (_jsxs("div", __assign({ className: styles
                            .slider('item')
                            .is({ active: carousel.isActive(i) })
                            .is({ loaded: isLoaded }) }, { children: [thumbnail && (_jsx(Picture, __assign({ src: urlFor(thumbnail).url() }, styles.slider('image')))), _jsx(Video, { video_link: video, onReady: function () { return setLoaded(true); }, autoPlay: false, muted: false })] }), (_a = item === null || item === void 0 ? void 0 : item.video) === null || _a === void 0 ? void 0 : _a.url));
                }) })), videos.length > 1 && (_jsxs("div", __assign({ className: styles.slider('controller') }, { children: [_jsx("div", __assign({ className: styles
                            .slider('controller-item')
                            .is({ active: carousel.hasPrevious() }), onClick: function () { return carousel.goPrevious(); } }, { children: _jsx(BackSvg, {}) })), _jsx("div", __assign({ className: styles
                            .slider('controller-item')
                            .is({ active: carousel.hasNext() }), onClick: function () { return carousel.goNext(); } }, { children: _jsx(NextSvg, {}) }))] })))] })));
};
export var VideoView = function (_a) {
    var _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
    var videos = _a.videos;
    var _m = useState(0), watchVideo = _m[0], setVideo = _m[1];
    var _o = useState(false), isLoaded = _o[0], setLoaded = _o[1];
    var _p = useState(true), onPictureLoaded = _p[0], setPictureloaded = _p[1];
    var _q = useState(false), onPlayclick = _q[0], setOnPlayclick = _q[1];
    useEffect(function () {
        setLoaded(false);
        setPictureloaded(false);
    }, [watchVideo]);
    return (_jsxs("div", __assign({ className: styles('videos') }, { children: [videos && (_jsxs("div", __assign({ className: styles('videos-container')
                    .is({
                    pictureloaded: onPictureLoaded,
                })
                    .is({ loaded: isLoaded }), onClick: function () { return setOnPlayclick(true); } }, { children: [_jsx("div", { className: styles('videos-container-playbutton').is({
                            loaded: onPlayclick,
                        }) }), _jsx(Video, { onReady: function () { return setLoaded(true); }, video_link: (_b = videos[watchVideo]) === null || _b === void 0 ? void 0 : _b.video }), _jsxs("div", __assign({ className: styles('videos-container-startscreen') }, { children: [((_c = videos[watchVideo]) === null || _c === void 0 ? void 0 : _c.thumbnail) && (_jsx(TransitionGroup, { children: _jsx(CSSTransition, __assign({ classNames: 'slide', timeout: { enter: 1000, exit: 1000 } }, { children: _jsx("img", { width: '100%', height: 'auto', className: styles('videos-container-startscreen-image'), alt: (_d = videos[watchVideo]) === null || _d === void 0 ? void 0 : _d.video.title, onLoad: function () { return setPictureloaded(true); }, src: urlFor((_e = videos[watchVideo]) === null || _e === void 0 ? void 0 : _e.thumbnail).url() }) }), (_g = (_f = videos[watchVideo]) === null || _f === void 0 ? void 0 : _f.video) === null || _g === void 0 ? void 0 : _g.url) })), _jsx(TransitionGroup, { children: _jsx(CSSTransition, __assign({ classNames: 'slide', timeout: { enter: 1000, exit: 1000 } }, { children: _jsx("div", __assign({ className: styles('videos-container-startscreen-title') }, { children: (_j = (_h = videos[watchVideo]) === null || _h === void 0 ? void 0 : _h.video) === null || _j === void 0 ? void 0 : _j.title })) }), (_l = (_k = videos[watchVideo]) === null || _k === void 0 ? void 0 : _k.video) === null || _l === void 0 ? void 0 : _l.url) })] }))] }))), videos && videos.length > 1 && (_jsx("div", __assign({ className: styles('videos-navigation') }, { children: videos.map(function (vid, i) {
                    var video = vid === null || vid === void 0 ? void 0 : vid.video;
                    var _a = vid.thumbnail, thumbnail = _a === void 0 ? null : _a;
                    if (!video) {
                        return null;
                    }
                    return (_jsxs("div", __assign({ className: styles('videos-navigation-thumbnail').is({
                            active: i === watchVideo,
                        }), onClick: function () { return setVideo(i); } }, { children: [thumbnail && (_jsx("img", { width: 200, height: 'auto', src: urlFor(vid === null || vid === void 0 ? void 0 : vid.thumbnail).url(), alt: vid.video.title, className: styles('videos-navigation-thumbnail-image') })), video.title && (_jsx("div", __assign({ className: styles('videos-navigation-thumbnail-title') }, { children: video.title })))] }), video.url));
                }) })))] })));
};
export { Home as default };
