import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Cookiewarning } from '../layout/Cookiewarning';
import { GATracking } from './trackers';
export var Tracking = function () {
    var acceptedCookies = localStorage
        ? !!localStorage.getItem('acceptedCookies')
        : false;
    var gaCode = 'UA-125543170-2';
    return (_jsxs(_Fragment, { children: [_jsx(Cookiewarning, { acceptedCookies: acceptedCookies }), gaCode && (_jsx(GATracking, { id: gaCode.toString(), acceptedCookies: acceptedCookies }))] }));
};
