var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Carousel, useCarousel } from '@codeurs/carousel';
import { useState } from 'react';
import placeholder from '../../assets/images/placeholder.jpg';
import { Theme } from '../Theme';
import { BackSvg } from '../ui/svg/back';
import { NextSvg } from '../ui/svg/next';
import { VideoPlayButton } from '../ui/Video';
import { urlFor } from '../util/imageBuilder';
import { Link } from '../util/link';
import { style } from '../util/style';
import './Work.scss';
var styles = style('work', ['slider', 'videoitem']);
var Work = function (_a) {
    var items = _a.items, pageData = _a.pageData;
    var categories = getCategories(items);
    return (_jsx("div", __assign({ className: styles() }, { children: _jsxs(Theme.Container, { children: [_jsx(Theme.H1, { children: pageData && pageData.title }), _jsx(WorkOverview, { categories: categories, items: items })] }) })));
};
export var WorkOverview = function (_a) {
    var items = _a.items, categories = _a.categories;
    return (_jsx("div", __assign({ className: styles('overview') }, { children: categories
            .sort(function (a, b) { return a.order - b.order; })
            .map(function (category) {
            var categoryItems = items
                .filter(function (item) {
                var _a, _b;
                var isOfCategory = false;
                if (((_a = item === null || item === void 0 ? void 0 : item.categories) === null || _a === void 0 ? void 0 : _a.length) === 0) {
                    return false;
                }
                (_b = item.categories) === null || _b === void 0 ? void 0 : _b.forEach(function (itemCategory) {
                    if (itemCategory.slug.current === category.slug.current) {
                        isOfCategory = true;
                    }
                });
                return isOfCategory;
            })
                .map(function (i) { return i; });
            if ((categoryItems === null || categoryItems === void 0 ? void 0 : categoryItems.length) === 0)
                return null;
            return (_jsxs("div", __assign({ className: styles('overview-category') }, { children: [_jsx(Theme.H2, { children: category.title }), _jsx(WorkSlider, { items: categoryItems })] }), category.slug.current));
        }) })));
};
export var WorkSlider = function (_a) {
    var items = _a.items;
    var carousel = useCarousel();
    if ((items === null || items === void 0 ? void 0 : items.length) === 0)
        return null;
    return (_jsxs("div", __assign({ className: styles.slider() }, { children: [_jsx(Carousel, __assign({}, carousel, { children: items.map(function (item, i) {
                    var thumbnail = item.thumbnail, externalLink = item.externalLink, work_title = item.work_title, title = item.title, year = item.year, info = item.info;
                    return (_jsx(WorkVideoLink, { isActive: carousel.isActive(i), img: thumbnail, externalLink: externalLink, title: work_title || title, year: year, info: info }, externalLink));
                }) })), items.length > 1 && (_jsxs("div", __assign({ className: styles.slider('controller') }, { children: [_jsx("div", __assign({ className: styles
                            .slider('controller-item')
                            .is({ active: carousel.hasPrevious() }), onClick: function () { return carousel.goPrevious(); } }, { children: _jsx(BackSvg, {}) })), _jsx("div", __assign({ className: styles
                            .slider('controller-item')
                            .is({ active: carousel.hasNext() }), onClick: function () { return carousel.goNext(); } }, { children: _jsx(NextSvg, {}) }))] })))] })));
};
export var WorkVideoLink = function (_a) {
    var img = _a.img, externalLink = _a.externalLink, isActive = _a.isActive, title = _a.title, info = _a.info;
    var background = urlFor(img).size(400, 226).url() || placeholder;
    var _b = useState(false), isLoaded = _b[0], setLoaded = _b[1];
    if (!externalLink)
        return null;
    return (_jsxs("div", __assign({ className: styles.videoitem() }, { children: [_jsx("div", __assign({ className: styles.videoitem('playbutton') }, { children: _jsx(VideoPlayButton, {}) })), _jsxs(WorkUseLink, __assign({ className: styles
                    .videoitem('container')
                    .mod({ background: !img })
                    .mod({ info: !!info })
                    .is({ active: isActive })
                    .is({ loaded: isLoaded }), externalLink: externalLink }, { children: [_jsx("div", __assign({ className: styles
                            .videoitem('container-background')
                            .is({ loaded: isLoaded }) }, { children: _jsx("img", { className: "background", alt: title, src: background, onLoad: function () { return setLoaded(true); } }) })), _jsx("div", __assign({ className: styles.videoitem('container-heading') }, { children: _jsx(Theme.H3, __assign({ className: styles.videoitem('container-heading-title') }, { children: title })) })), _jsx("div", __assign({ className: styles.videoitem('container-info') }, { children: _jsx(Theme.Wysiwyg, { children: info }) }))] }))] })));
};
export var WorkUseLink = function (_a) {
    var externalLink = _a.externalLink, className = _a.className, children = _a.children;
    if (!externalLink)
        return _jsx("div", { children: children });
    return (_jsx(Link, __assign({ className: "".concat(className === null || className === void 0 ? void 0 : className.toString(), " is-link"), to: externalLink, target: '_blank' }, { children: children })));
};
var getCategories = function (items) {
    var categories = [];
    items === null || items === void 0 ? void 0 : items.forEach(function (item) {
        if (null === item ||
            null === item.categories ||
            item.categories.length === 0) {
            return;
        }
        if (categories.find(function (category) {
            return item.categories.find(function (itemCategory) { return itemCategory.slug.current === category.slug.current; });
        })) {
            return;
        }
        categories.push.apply(categories, item.categories.map(function (category) { return category; }));
    });
    return categories;
};
export { Work as default };
