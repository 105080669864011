var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Dialog, Slide } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import Hamburger from 'react-hamburger-menu';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import { Logo } from '../partials/Logo';
import { Theme } from '../Theme';
import { style } from '../util/style';
import './Header.scss';
var styles = style('header', [
    'logo',
    'nav',
    'navlink',
    'mobilenav',
    'hamburger'
]);
export var TransitionComponent = React.forwardRef(function Transition(props, ref) {
    return (_jsx(Slide, __assign({ direction: "right", ref: ref }, props, { children: props.children })));
});
export var Header = function (_a) {
    var navigation = _a.navigation, currentPath = _a.currentPath;
    var navigate = useNavigate();
    var _b = useState(false), isOpen = _b[0], setOpen = _b[1];
    useEffect(function () { return setOpen(false); }, [navigate.name]);
    return (_jsxs("header", __assign({ className: styles() }, { children: [_jsx(Theme.Container, { children: _jsxs("div", __assign({ className: styles('row') }, { children: [_jsx(HeaderLogo, {}), _jsx(HeaderNav, { navigation: navigation, currentPath: currentPath, setNav: function (state) { return setOpen(state); } }), _jsx("span", __assign({ className: styles.hamburger(), onClick: function () { return setOpen(!isOpen); } }, { children: _jsx(Hamburger, { isOpen: isOpen, color: "#3d3d3d", height: 10, width: 17, borderRadius: 5, menuClicked: function () { return setOpen(!isOpen); } }) }))] })) }), _jsx("div", __assign({ className: styles('mobilenav') }, { children: _jsx(Dialog, __assign({ open: isOpen, TransitionComponent: TransitionComponent, onClose: function () { return setOpen(false); }, style: { top: 73 }, BackdropComponent: null, hideBackdrop: true, fullScreen: true, classes: {
                        root: styles('mobilenav-root').toString(),
                        paper: styles('mobilenav-paper').toString()
                    } }, { children: _jsx(HeaderMobileNav, { setNav: setOpen, navigation: navigation, currentPath: currentPath }) })) }))] })));
};
var HeaderLogo = function () { return (_jsx(Link, __assign({ to: '/home', title: "Homepage", className: styles.logo() }, { children: _jsx(Logo, {}) }))); };
var HeaderNav = function (_a) {
    var navigation = _a.navigation, setNav = _a.setNav, currentPath = _a.currentPath;
    if (!navigation)
        return null;
    return (_jsx("nav", __assign({ className: styles.nav() }, { children: navigation &&
            navigation.map(function (nav) {
                return nav && (_jsx(Link, __assign({ to: nav.slug, onClick: function () { return setNav && setNav(false); }, className: styles.navlink().is({ active: nav.slug === currentPath }) }, { children: nav.title }), nav.slug));
            }) })));
};
export var HeaderMobileNav = function (_a) {
    var navigation = _a.navigation, setNav = _a.setNav;
    return (_jsx("nav", __assign({ className: styles.mobilenav() }, { children: navigation && (_jsx("div", __assign({ className: styles.mobilenav('navigation') }, { children: _jsx(Theme.Container, { children: _jsx("div", __assign({ className: styles.mobilenav('navigation-items') }, { children: navigation.map(function (item) {
                        return item && (_jsx(Link, __assign({ to: item.slug, onClick: function () { return setNav && setNav(false); }, className: styles.navlink() }, { children: item.title }), item.slug));
                    }) })) }) }))) })));
};
