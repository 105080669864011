var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { Button } from '../ui/Button';
import { style } from '../util/style';
import './Cookiewarning.scss';
var styles = style('cookiewarning');
export var Cookiewarning = function (_a) {
    var acceptedCookies = _a.acceptedCookies;
    var _b = useState(acceptedCookies), isHidden = _b[0], setHidden = _b[1];
    return (_jsxs("div", __assign({ className: styles.is({ hidden: isHidden }) }, { children: [_jsx("div", __assign({ className: styles('content') }, { children: _jsx("p", __assign({ className: styles('text') }, { children: "This website uses cookies to improve your browsing experience." })) })), _jsx(Button, __assign({ onClick: function () {
                    localStorage.setItem('acceptedCookies', 'true');
                    setHidden(true);
                } }, { children: "Accept cookies" }))] })));
};
