var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import './Theme.scss';
import { useEffect, useState } from 'react';
import { createTheme } from './util/createtheme';
import { Link as UtilLink } from './util/link';
import { style } from './util/style';
import { Trusted } from './util/trusted';
var styles = style('theme', [
    'container',
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'p',
    'wysiwyg',
    'button',
    'buttontext',
    'buttonicon',
    'link',
    'linktext',
    'linkicon'
]);
var Container = function (_a) {
    var children = _a.children, props = __rest(_a, ["children"]);
    return (_jsx("div", __assign({}, props, { className: styles.container() }, { children: children })));
};
var H1 = function (_a) {
    var _b = _a.as, Tag = _b === void 0 ? 'h1' : _b, props = __rest(_a, ["as"]);
    var _c = useState(false), isLoaded = _c[0], setLoaded = _c[1];
    useEffect(function () { return setLoaded(true); }, []);
    return (_jsx(Tag, __assign({}, props, { className: styles
            .h1()
            .is({ loaded: isLoaded })
            .mergeProps(props) })));
};
var H2 = function (_a) {
    var _b = _a.as, Tag = _b === void 0 ? 'h2' : _b, props = __rest(_a, ["as"]);
    return (_jsx(Tag, __assign({}, props, { className: styles.h2().mergeProps(props) })));
};
var H3 = function (_a) {
    var _b = _a.as, Tag = _b === void 0 ? 'h3' : _b, props = __rest(_a, ["as"]);
    return (_jsx(Tag, __assign({}, props, { className: styles.h3().mergeProps(props) })));
};
var H4 = function (_a) {
    var _b = _a.as, Tag = _b === void 0 ? 'h4' : _b, props = __rest(_a, ["as"]);
    return (_jsx(Tag, __assign({}, props, { className: styles.h4().mergeProps(props) })));
};
var H5 = function (_a) {
    var _b = _a.as, Tag = _b === void 0 ? 'h5' : _b, props = __rest(_a, ["as"]);
    return (_jsx(Tag, __assign({}, props, { className: styles.h5().mergeProps(props) })));
};
var P = function (props) { return (_jsx("p", __assign({}, props, { className: styles.p().mergeProps(props) }))); };
var Wysiwyg = function (props) { return (_jsx(Trusted, __assign({}, props, { className: styles.wysiwyg().mergeProps(props) }))); };
var Button = function (_a) {
    var as = _a.as, mod = _a.mod, iconbefore = _a.iconbefore, iconafter = _a.iconafter, children = _a.children, props = __rest(_a, ["as", "mod", "iconbefore", "iconafter", "children"]);
    var ButtonTag = as || UtilLink;
    return (_jsx(ButtonTag, __assign({}, props, { className: styles
            .button()
            .mod(mod)
            .mergeProps(props) }, { children: _jsx(ButtonText, { children: children }) })));
};
var ButtonText = function (props) { return (_jsx("span", __assign({}, props, { className: styles.buttontext().mergeProps(props) }))); };
var Link = function (_a) {
    var children = _a.children, props = __rest(_a, ["children"]);
    return (_jsx(UtilLink, __assign({}, props, { className: styles.link().mergeProps(props) }, { children: _jsx(LinkText, { children: children }) })));
};
var LinkText = function (props) { return (_jsx("span", __assign({}, props, { className: styles.linktext().mergeProps(props) }))); };
export var defaultTheme = {
    Container: Container,
    H1: H1,
    H2: H2,
    H3: H3,
    H4: H4,
    H5: H5,
    P: P,
    Wysiwyg: Wysiwyg,
    Button: Button,
    ButtonText: ButtonText,
    Link: Link,
    LinkText: LinkText,
};
export var Theme = createTheme(defaultTheme);
