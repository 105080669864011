var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { forwardRef } from 'react';
import parse from 'url-parse';
export var Link = forwardRef(function (props, ref) {
    var children = props.children, as = props.as, to = props.to, rest = __rest(props, ["children", "as", "to"]);
    var href = to;
    var parsed = parse(href);
    var isFile = parsed.pathname
        .split('/')
        .pop()
        .includes('.');
    var isExternal = parsed.hostname !== window.location.hostname;
    var Tag = 'a';
    var attrs = isFile || isExternal
        ? href
            ? {
                href: href,
                ref: ref,
                target: isExternal || isFile ? '_blank' : '_self',
                rel: isExternal ? 'external nofollow noopener' : null
            }
            : {}
        : { to: href, innerref: ref, activeClassName: 'is-active' };
    return (_jsx(Tag, __assign({}, attrs, rest, { children: children })));
});
