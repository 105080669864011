var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { jsx as _jsx } from "react/jsx-runtime";
// tslint:disable
import { Component } from 'react';
import loadScript from 'simple-load-script';
var currentUrl = function () { return location.pathname + location.search; };
var Tracker = /** @class */ (function (_super) {
    __extends(Tracker, _super);
    function Tracker() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Tracker.prototype.render = function () {
        var current = currentUrl();
        if (current === this.url)
            return null;
        this.props.children({ url: (this.url = current), title: document.title });
        return null;
    };
    return Tracker;
}(Component));
export { Tracker };
var GATracking = /** @class */ (function (_super) {
    __extends(GATracking, _super);
    function GATracking(props) {
        var _this = _super.call(this, props) || this;
        var _a = _this.props, id = _a.id, acceptedCookies = _a.acceptedCookies;
        loadScript("https://www.googletagmanager.com/gtag/js?id=".concat(id)).catch(console.error);
        window.dataLayer = window.dataLayer || [];
        window.gtag = function () {
            window.dataLayer.push(arguments);
        };
        gtag('js', new Date());
        gtag('config', id, { anonymize_ip: !acceptedCookies });
        return _this;
    }
    GATracking.prototype.render = function () {
        var acceptedCookies = this.props.acceptedCookies;
        return (_jsx(Tracker, { children: function (_a) {
                var url = _a.url, title = _a.title;
                return gtag('event', 'page_view', {
                    page_path: url,
                    page_title: title,
                    anonymize_ip: !acceptedCookies,
                });
            } }));
    };
    return GATracking;
}(Component));
export { GATracking };
var GTMTracking = /** @class */ (function (_super) {
    __extends(GTMTracking, _super);
    function GTMTracking(props) {
        var _this = _super.call(this, props) || this;
        var id = _this.props.id;
        loadScript("https://www.googletagmanager.com/gtm.js?id=".concat(id, "&l=dataLayer")).catch(console.error);
        window.dataLayer = window.dataLayer || [];
        dataLayer.push({
            'gtm.start': new Date().getTime(),
            event: 'gtm.js',
        });
        return _this;
    }
    GTMTracking.prototype.render = function () {
        var acceptedCookies = this.props.acceptedCookies;
        return (_jsx(Tracker, { children: function (_a) {
                var url = _a.url, title = _a.title;
                return dataLayer.push({
                    event: 'VirtualPageview',
                    virtualPageURL: url,
                    virtualPageTitle: title,
                    anonymize_ip: !acceptedCookies,
                });
            } }));
    };
    return GTMTracking;
}(Component));
export { GTMTracking };
var FBPTracking = /** @class */ (function (_super) {
    __extends(FBPTracking, _super);
    function FBPTracking() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.loaded = false;
        return _this;
    }
    FBPTracking.prototype.render = function () {
        var _a = this.props, id = _a.id, acceptedCookies = _a.acceptedCookies;
        if (this.loaded || !acceptedCookies)
            return;
        this.loaded = true;
        loadScript('https://connect.facebook.net/en_US/fbevents.js').catch(console.error);
        window.fbq = function () {
            fbq.callMethod
                ? fbq.callMethod.apply(fbq, arguments)
                : fbq.queue.push(arguments);
        };
        fbq.loaded = true;
        fbq.version = '2.0';
        fbq.queue = [];
        fbq('init', id);
        fbq('track', 'PageView');
        return null;
    };
    return FBPTracking;
}(Component));
export { FBPTracking };
var HotjarTracking = /** @class */ (function (_super) {
    __extends(HotjarTracking, _super);
    function HotjarTracking() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.loaded = false;
        return _this;
    }
    HotjarTracking.prototype.render = function () {
        var _a = this.props, id = _a.id, version = _a.version, acceptedCookies = _a.acceptedCookies;
        if (this.loaded || !acceptedCookies)
            return;
        this.loaded = true;
        loadScript("https://static.hotjar.com/c/hotjar-".concat(id, ".js?sv=").concat(version)).catch(console.error);
        window.hj =
            window.hj ||
                function () {
                    ;
                    (window.hj.q = window.hj.q || []).push(arguments);
                };
        window._hjSettings = { hjid: id, hjsv: version };
        return null;
    };
    return HotjarTracking;
}(Component));
export { HotjarTracking };
