import sanityClient from "@sanity/client"

import sanityJson from '../../../sanity.json'

export default sanityClient({
    projectId: sanityJson.api.projectId,
    dataset: sanityJson.api.dataset,
    apiVersion: '2021-10-29',
    useCdn: true,
    token: 'skz4Yda35WiPewIem9woXBbO21Byxi0u7XXLUxtrfSCJYXi7mE9317EVjdoUsFm3l5SzPPOgMXS6G7c7sDvjeVs00LqZzdmEL48AAyfnjh0OTg5dJ7Bwrd1I42avLqDROhsIb8UXh92njnB99J6gcQApSmMvc2srJaCDDVqkcMIWV3ghMj1B'
})